.row-1 div {
  border: none;

}

.typ {
  overflow-y: scroll;
  /* border: 1px solid black; */
  overflow-x: auto;
  /* width: 100vw !important; */
}

.hedr {
  width: 1326px;
  overflow-x: hidden;
}

.tkmh {
  width: 1337px;
  overflow-x: hidden;
}

.hading {
  background-color: #CD3839;
  color: #fff;
  width: 130px;
  text-wrap: wrap;
  text-align: center;
  padding: 5px;
  border: 1px solid black;

}


.hadingno {
  background-color: #CD3839;
  color: #fff;
  width: 57px !important;
  text-wrap: wrap;
  text-align: center;
  padding: 5px;

  border: 1px solid black;

}

.hadingdata {
  width: 130px;
  text-wrap: wrap;
  text-align: center;
  padding: 5px;
  border: 1px solid black;
  font-size: 14px;

}

.hadingdatano {
  width: 57px !important;
  text-wrap: wrap;
  text-align: center;
  padding: 5px;
  border: 1px solid black;


}

.Logout {
  background-color: #CD3839;
  color: #fff;
  margin: 5px;
  padding: 0px 10px 0px 10px !important;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  align-items: center;
  border: none;
  display: flex;

}

.Close {
  /* background-color: #CD3839; */
  color: #fff;
  margin: 5px;
  padding: 0px 10px 5px 10px;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
  align-items: center;
  border: none;
}



.nav {
  color: #CD3839 !important;
  font-size: 20px;
  margin-left: 10px;
  margin-right: 10px;
  text-decoration: none;
}

.btn {
  padding: 5px 5px 5px 5px !important;
}